
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import HistoryMapping from "@/components/1crm/product/history-mapping/history-mapping.vue";
// import UploadComponent from "@/components/1crm/legal/legalitas/superadmin/upload-modal.vue";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    HistoryMapping,
    // UploadComponent,
  },
  props: {
    widgetClasses: String,
  },
  // beforeRouteLeave(to, from, next) {
  //   const answer = window.confirm(
  //     "Do you really want to leave? you have unsaved    changes!"
  //   );
  //   if (answer) {
  //     next();
  //   } else {
  //     next(false);
  //   }
  // },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH);

    setCurrentPageBreadcrumbs("History Mapping", ["Daftar Pembelian"]);
  },
});
