
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

import { useToast } from "vue-toastification";
import { ref } from "yup";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import { useRoute } from "vue-router";
moment.locale("id");

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: [
        {
          createTime: "",
          creatorId: "",
          updateTime: "",
          editorId: "",
          isDelete: false,
          deleteBy: "",
          deleteTime: "",
          mappingType: "",
          mappingDate: "",
          batchId: "",
          purchaseId: "",
          merchantId: "",
          creatorName: "",
          totalMapping: 0,
          _id: "",
        },
      ],
      resume: {
        createTime: "",
        creatorId: "",
        updateTime: "",
        editorId: "",
        isDelete: false,
        deleteBy: "",
        deleteTime: "",
        purchaseDate: "",
        merchantId: "",
        totalCard: 0,
        totalCardFailed: 0,
        blockedMapping: false,
        _id: "",
        merchantName: "",
        startMapping: "",
        lastMapping: "",
        totalCardActive: 0,
        totalCardSuccess: 0,
        totalCardRemains: 0,
      },
      listCategory: [],
      listStatus: [],
      isLoading: false,
      dateNow: moment().format("DD-MMMM-YYYY LT"),
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      purchaseId: "",
      dateFilter: "",
      role: "",
      filter: false,
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      name: "",
      categoryId: "",
    };
  },

  beforeMount() {
    // store.dispatch(Actions.VERIFY_AUTH);
    this.role = JSON.parse(localStorage.getItem("user_account")!).role;
    const route = useRoute();
    this.purchaseId = "" + route.params.id;
    this.getResume();
    this.getData(this.paging.size, this.paging.page);
    this.getListKategori();
    setCurrentPageBreadcrumbs("Data Produk", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getData(size, page) {
      const route = useRoute();
      var itemId = this.purchaseId;

      this.isLoading = true;

      let search =
        this.dateFilter !== ""
          ? `&mappingDate=${moment(this.dateFilter).format("YYYY-MM-DD")}`
          : "";
      let valcategoryId = "";
      if (this.categoryId) {
        valcategoryId = this.categoryId;
      }
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }

      console.log(search);
      ApiService.getWithoutSlug(
        "crmv2/main_card/card_stock/" +
          office +
          "history/all?" +
          "page=" +
          page +
          "&size=" +
          size +
          "&sort_by=createTime&dir=1&purchaseId=" +
          itemId +
          search
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    changeDate(e) {
      this.getData(this.paging.size, this.paging.page);
    },
    getResume() {
      const route = useRoute();
      var itemId = this.purchaseId;
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      ApiService.getWithoutSlug(
        "crmv2/main_card/card_purchasing/" + office + itemId
      )
        .then((resp) => {
          console.log(resp.data);
          this.resume = resp.data;
          this.resume.purchaseDate = moment(resp.data.purchaseDate).format('DD-MM-YYYY');
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    getListKategori() {
      ApiService.getWithoutSlug("crmv2/main_order/product/combo_catagory").then(
        ({ data }) => {
          this.listCategory = data;
          localStorage.setItem("listCategory", JSON.stringify(data));
        }
      );
    },

    unduhId(e) {
      const toast = useToast();
      this.isLoading = true;
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      console.log(e)
      ApiService.getDownload(
        `crmv2/main_card/card_stock/${office}download_stock/` + e
      )
        .then((response) => {
          // this.handleBlobResponse(response.data, item, extension);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          // console.log(res.data);
          var extension = "xls";
          link.setAttribute(
            "download",
            "data_kartu_termaping_" + this.resume.merchantName + "." + extension
          ); // nama file dan extension sesuaikan dengan file yang di download
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;
          toast.success("Sukses Unduh File");
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.detail);
          this.isLoading = false;
        });
    },

    pilihKategori(event) {
      // console.log(event.target.value);
      console.log(event);
      // this.schoolId = event;
      if (event) {
        this.categoryId = event;
      } else {
        this.categoryId = "";
      }
      // this.getQtyStudent();
      localStorage.setItem("sekolahId", JSON.stringify(event));
      this.getData(this.paging.size, this.paging.page);
    },

    handleDelete(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },

    filterShowing() {
      this.filter = !this.filter;
    },
    deleteRow(item) {
      const toast = useToast();
      this.isLoading = true;

      // ApiService.delete(
      //   "crmv2/main_order/product/" + item._id
      // )
      //   .then((res) => {
      //     toast.success("Hapus Berhasil");

      //     this.getData(this.paging.size, this.paging.page);
      //     this.isLoading = false;
      //   })

      //   .catch((error) => {
      //     toast.error("Gagal Hapus");
      //     this.isLoading = false;
      //   });
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize() {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },

    changeModal() {},

    dateTime(val) {
      if (val) {
        return moment(val).format("DD-MMMM-YYYY LT");
      } else {
        return "-";
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
});
